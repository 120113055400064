<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">分账比例配置</div>
		</div>
		<div class="flex">
			<div></div>
			<el-button @click="addshow=true;addtype=1;resource='1'">添加配置</el-button>
		</div>
		<div class="table-content">
			<el-table :cell-style="{ textAlign: 'center' }"  :header-cell-style="{textAlign: 'center',backgroundColor:'#F5F7FA',color:'#333'}" :border="true" :data="data" style="width: 100%">
				<el-table-column prop="supplierName" label="供应商名称" width="300">
				</el-table-column>
				<el-table-column prop="supplierAccountper" label="供应商分账比例">
                    <template slot-scope="scope">
                        <span >{{scope.row.supplierAccountper + '%'}}</span>
                    </template>
				</el-table-column>
				<el-table-column prop="platformAccountper" label="平台分账比例">
                    <template slot-scope="scope">
                        <span >{{scope.row.platformAccountper + '%'}}</span>
                    </template>
				</el-table-column>
                <el-table-column prop="updateTime" label="操作时间" >
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="300">
					<template style="display: flex;" slot-scope="scope">
						<el-button @click.native.prevent="updataclick(scope.$index, data)" type="text" size="small">
							修改
						</el-button>
						<el-button @click.native.prevent="deleteList(scope.$index, data)" type="text" size="small">
							删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 新增的弹窗 -->
		<el-dialog :visible.sync="addshow" width="700px" :before-close="handleClose" :title="addtype == 1 ? '添加' : '编辑'">
			<el-form class="form_box" :model="sizeForm" label-position='right' ref="sizeForm" size="small">
				<el-form-item  v-if="addtype == 1">
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:130px;text-align:right;margin-right:10px;"><span style="color:red">*</span>供应商名称：</p>
							<el-select  v-model="sizeForm.supplierId" clearable  >
								<el-option v-for="item in supplierList" :key="item.id" :label="item.supplierName" :value="item.id"> </el-option>
							</el-select>
						</div>
					</template>
				</el-form-item>
				<el-form-item  v-if="addtype == 2">
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:130px;text-align:right;margin-right:10px;"><span style="color:red">*</span>供应商名称：</p>
							<el-input :disabled="true" class="numberInput" width="200px" v-model="sizeForm.supplierName"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:130px;text-align:right;margin-right:10px;"><span style="color:red">*</span>供应商分账比例：</p>
							<el-input class="numberInput" min="0" type="number" width="200px" v-model="sizeForm.supplierAccountper" @keyup.native="keyVerify($event)"><span style="color:#333;" slot="append">%</span></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:130px;text-align:right;margin-right:10px;"><span style="color:red">*</span>平台分账比例：</p>
							<el-input class="numberInput" min="0" type="number" v-model="sizeForm.platformAccountper" @keyup.native="keyVerify($event)"><span style="color:#333;" slot="append">%</span></el-input>
						</div>
					</template>
				</el-form-item>
                <p style="text-align:center;margin-right:100px;margin-bottom:10px;">两者比例相加必须等于100%</p>
				<el-form-item size="large" style="display:flex;justify-content:center;">
					<el-button v-if="addtype == 2" type="primary"  @click.native.prevent="onUpdata('sizeForm')">保存</el-button>
					<el-button v-if="addtype == 1" type="primary"  @click.native.prevent="submitForm('sizeForm')">保存</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<div class="">
			<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
				:page-count="pageCount" :page-size="pageSize" layout="total, prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		splitaccountList,splitaccountAdd,splitaccountUpdate,splitaccountDelete,splitaccountsupplierList
	} from "@/api/platform/index";
	export default {
		name: "proportionSet",
		data() {
			return {
				resource: "1",
				data: [],
                supplierList:[],
				addshow: false,
				sizeForm: {
                    id:'',
                    supplierId:'',
                    supplierName:'',
                    supplierAccountper:'',
                    platformAccountper:'',
				},
				page: 1,
				pageSize: 10,
				total: 0, //总数据
				currentPage: 0, //当前页页数
				pageCount: 0, //总页数
				addtype: '',
				id: 0,
			};
		},
		mounted() {
			let that = this;
			that.list();
            that.getSupplierList();
		},
		created() {
		},
		methods: {
			//获取列表
			list() {
				splitaccountList({
					page: this.page,
					size: this.pageSize,
				}).then(res => {
					this.data = res.data.records;
					this.total = res.data.total;
					this.currentPage = res.data.current;
					this.pageCount = res.data.pages;
					if (res.data.current > res.data.pages && res.data.pages != 0) {
						this.page = res.data.pages;
						this.list();
					}
				})
			},

            // 获取供应商列表
            getSupplierList() {
                let data = {};
				splitaccountsupplierList(data).then(res => {
                    if (res.code == 200) {
                        this.supplierList = res.data;
                    }
				})
			},

			//分页
			handleCurrentChange(val) {
				this.page = val;
				this.list();
			},

			//删除
			deleteList(cur, tab) {
				this.$confirm('您确定要删除[' + tab[cur].supplierName + ']供应商的分账比例配置?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					splitaccountDelete(tab[cur].id).then(res => {
						if (res.code == 200) {
							this.$message.success('删除成功');
							this.page = 1
            				this.getSupplierList();
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					})
				})
			},
			
			//关闭提醒
			handleClose(done) {
				this.sizeForm = {
					id: '',
					supplierId: '',
					supplierName:'',
					supplierAccountper:'',
					platformAccountper:''
				}
				this.addshow = false
			},

			//编辑按钮点击
			updataclick(i,tab){
                console.log(tab[i]);
				this.sizeForm = {
                    id: tab[i].id,
                    supplierId: tab[i].supplierId,
                    supplierAccountper: tab[i].supplierAccountper,
                    platformAccountper: tab[i].platformAccountper,
                    supplierName: tab[i].supplierName,
				}
				this.id = tab[i].id
				this.addtype = 2
				this.addshow = true
			},

			//添加提交
			submitForm(formName) {
				if(this.addshow){
					this.$refs[formName].validate((valid) => {
						if (valid) {
                            let id = this.$refs[formName].model.supplierId;
                            if(id == null || id == ''){
								this.$message.warning('请先选择供应商');
                            }else {
								if(this.$refs[formName].model.supplierAccountper.toString() == ''){
									this.$message.warning('供应商分账比例不能为空');
								}else if(Number(this.$refs[formName].model.supplierAccountper) < 0){
									this.$message.warning('供应商分账比例不能为负数');
								}else if(this.$refs[formName].model.supplierAccountper.toString() == ''){
									this.$message.warning('平台分账比例不能为负数');
								}else if(Number(this.$refs[formName].model.platformAccountper) < 0){
									this.$message.warning('平台分账比例不能为负数');
								}else if(Number(this.$refs[formName].model.supplierAccountper)  + Number(this.$refs[formName].model.platformAccountper)  != 100){
						            this.$message.warning('两者比例相加必须等于100%');
								}else{
									var index = this.supplierList.findIndex((value)=> value.id == id);
									splitaccountAdd({
										supplierId: this.$refs[formName].model.supplierId,
										supplierName: this.supplierList[index].supplierName,
										supplierAccountper: this.$refs[formName].model.supplierAccountper,
										platformAccountper:this.$refs[formName].model.platformAccountper,
									}).then(res=>{
										if(res.code == 200){
											this.$message.success('添加成功');
											this.sizeForm = {
												id: '',
												supplierId: '',
												supplierName:'',
												supplierAccountper:'',
												platformAccountper:''
											}
											this.addshow = false
											this.page = 1
											this.getSupplierList();
											this.list();
										}else{
											this.$message.error(res.msg);
										}
									}).catch(err => {
										this.$message.error(err.msg);
									})
								}
							}
						} else {
							return false;
						}
					});
				}
			},

			//编辑提交
			onUpdata(formName){
				if(this.addshow){
					this.$refs[formName].validate((valid) => {
						if (valid) {
							if(this.$refs[formName].model.supplierName == ''){
								this.$message.warning('供应商名称不能为空');
							}else {
								if(Number(this.$refs[formName].model.supplierAccountper)  + Number(this.$refs[formName].model.platformAccountper)  != 100){
					            	this.$message.warning('两者比例相加必须等于100%');
								}else{
									splitaccountUpdate({
										supplierName: this.$refs[formName].model.supplierName,
										supplierId: this.$refs[formName].model.supplierId,
										supplierAccountper: this.$refs[formName].model.supplierAccountper,
										platformAccountper: this.$refs[formName].model.platformAccountper,
										id: this.$refs[formName].model.id
									}).then(res=>{
										if (res.code == 200) {
											this.$message.success('修改成功');
											this.sizeForm = {
												id: '',
												supplierId: '',
												supplierName:'',
												supplierAccountper:'',
												platformAccountper:''
											}
											this.addshow = false
											this.page = 1
											this.list();
										} else {
											this.$message.error(res.msg);
										}
									}).catch(err => {
										this.$message.error(err.msg);
									})
								}
							}
						} else {
							return false;
						}
					});
				}
			},
		}
	}
</script>

<style scoped>
	.table-content {margin-top: 20px;}
	.active {color: #049EC4;}
	.form_box{padding:0 50px;}
	.avatar{width:150px;}
	.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
	.flex{display:flex;align-items:center;justify-content:space-between;}

	.meunTop{display:flex;align-items:center;margin-bottom:20px;}
	.meunLine{width:3px;height:20px;background-color:#FFA218;}
	.meunTitle{font-size:20px;margin-left:10px;}
    .el-input__inner{
        -webkit-appearance: none !important;
        -moz-appearance: textfield !important;
    }
</style>
